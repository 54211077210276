import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import FaqAccordionSection from '../accordion/FaqAccordionSection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


const FaqMain = () => {
  const { t, i18n } = useTranslation();
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const titles = {
    en: "Regaleyes | Faq's",
    ar: "ريجل ايز | الأسئلة الشائعة"
  };
  const descriptions = { // Fixed typo here
    en: "Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort.",
    ar: "اكتشف Regaleyes، وجهتك الأولى للحصول على منتجات بصرية عالية الجودة. استكشف مجموعتنا الواسعة من العدسات والنظارات والإكسسوارات المصممة للأناقة والراحة."
  };

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
  const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
  const currentDescription = i18n.language === 'ar' ? descriptions.ar : descriptions.en;


  return (
    <>
                <Helmet>
                <title>{currentTitles}</title>
                <meta name="description" content={currentDescription} />
                <meta name="keywords" content={currentKeywords} />
            </Helmet> 
        <BreadcrumbSection title={t('FAQ Page')} current={t('FAQ')}/>
        <FaqAccordionSection/>
    </>
  )
}

export default FaqMain