import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection'
import AuthenticationLoginSection from '../authentication/AuthenticationLoginSection'
import { useTranslation } from 'react-i18next'

const LoginMain = () => {
  const { t } = useTranslation();
  return (
    <>
        <BreadcrumbSection title={t('Login')} current={t('Login')} />
        <AuthenticationLoginSection/>
    </>
  )
}

export default LoginMain