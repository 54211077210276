import React from 'react';
import Layout from '../components/layout/Layout';
import PageDetailsMain from '../components/main/PageDetailsMain';


const Page = () => {
  return (
    <Layout>
        <PageDetailsMain/>
    </Layout>
  )
}

export default Page