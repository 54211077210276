import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import AuthenticationSection from '../authentication/AuthenticationSection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const AccountMain = () => {
  const { t, i18n } = useTranslation(); // Destructure i18n here

  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };

  const titles = {
    en: "Regaleyes | My Account",
    ar: "ريجل ايز | الملف الشخصي"
  };

  const descriptions = {
    en: "Discover Regaleyes, your premier destination for high-quality optical products. Explore our extensive range of lenses, eyewear, and accessories designed for style and comfort.",
    ar: "اكتشف Regaleyes، وجهتك الأولى للحصول على منتجات بصرية عالية الجودة. استكشف مجموعتنا الواسعة من العدسات والنظارات والإكسسوارات المصممة للأناقة والراحة."
  };

  // Get the current language
  const currentLang = i18n.language; // or use i18n.languages[0] for the first language
  const currentKeywords = keywords[currentLang];
  const currentTitles = titles[currentLang];
  const currentDescription = descriptions[currentLang];

  return (
    <>
      <Helmet>
        <title>{currentTitles}</title>
        <meta name="description" content={currentDescription} />
        <meta name="keywords" content={currentKeywords} />
      </Helmet>

      <BreadcrumbSection title={t('My Account')} current={t('My Account')} />
      <AuthenticationSection />
    </>
  );
}

export default AccountMain;