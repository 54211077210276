import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const apiUrl = import.meta.env.VITE_API_URL;

const BookingFormSection = () => {
  const { t } = useTranslation(); 

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isTimeVisible, setIsTimeVisible] = useState(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [age, setAge] = useState(''); // New state variable for age
  const [haveGlasses, setHaveGlasses] = useState('0'); // New state variable for glasses

  const generateTimeOptions = () => {
    const options = [];
    
    const morningStart = new Date();
    morningStart.setHours(10, 0, 0);
    const morningEnd = new Date();
    morningEnd.setHours(14, 0, 0);

    while (morningStart <= morningEnd) {
      options.push(morningStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      morningStart.setMinutes(morningStart.getMinutes() + 30);
    }

    const eveningStart = new Date();
    eveningStart.setHours(20, 0, 0);
    const eveningEnd = new Date();
    eveningEnd.setHours(22, 30, 0);

    while (eveningStart <= eveningEnd) {
      options.push(eveningStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      eveningStart.setMinutes(eveningStart.getMinutes() + 30);
    }

    return options;
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const dayOfWeek = selectedDate.getDay();
    if (dayOfWeek !== 5) {
      setSelectedDate(e.target.value);
    } else {
      alert('Friday is not allowed');
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !selectedDate || !selectedTime || !age) {
        toast.error(t('Please fill out all fields.'), { position: 'top-right' });
    } else if (!isValidEmail(email)) {
        toast.warning(t('Please provide a valid email address.'), { position: 'top-right' });
    } else {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('date', selectedDate);
        formData.append('time', selectedTime);
        formData.append('age', age);
        formData.append('have_glasses', haveGlasses);

        fetch(`${apiUrl}/api/add-booking`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Log the response data
                if (data.success) {
                    toast.success(t('Form submitted successfully!'), { position: 'top-right' });
                    // Reset form fields
                    setName('');
                    setEmail('');
                    setPhone('');
                    setSelectedDate('');
                    setSelectedTime('');
                    setAge('');
                    setHaveGlasses('0');
                } else {
                  toast.success(t('Form submitted successfully!'), { position: 'top-right' });
                }
            })
            .catch((err) => { // Use a different variable name here
                console.error('Error:', err); // Log the error
                toast.error(t('Error submitting form.'), { position: 'top-right' });
            });
    }
};
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s @]+$/;
    return emailRegex.test(email);
  };

  return (
    <form action="#" onSubmit={handleFormSubmit}>
      <div className="row g-xl-4 g-3">
        <div className="col-12 col-xxs-12">
          <input
            type="text"
            name="name"
            id="commenter-name"
            placeholder={t('Full Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="col-6 col-xxs-12">
          <input
            type="email"
            name="email"
            id="commenter-email"
            placeholder={t('Email Address')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <input
            type="number"
            name="phone"
            id="commenter-number"
            placeholder={t('Phone Number')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="col-6">
          <label>{t('Select Date')}</label>
          <br />
          <input
            type="date"
            name="date"
            id="date"
            value={selectedDate}
            onChange={handleDateChange}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        <div className="col-6">
          <label>{t('Select Time')}</label>
          <br />
          <select name="time" id="time" value={selectedTime} onChange={handleTimeChange}>
            <option value="" disabled>{t('Select time')}</option>
            {generateTimeOptions().map((timeOption) => (
              <option key={timeOption} value={timeOption}>{timeOption}</option>
            ))}
          </select>
        </div>

        <div className="col-6">
          <label>{t('Age')}</label>
          <br />
          <input
            type="number"
            name="age"
            id="age"
            placeholder={t('Age')}
            value={age}
            onChange={(e) => setAge(e.target.value)}
            min="1"
            max="90"
          />
        </div>

        <div className="col-6">
          <label>{t('Do you wear glasses?')}</label>
          <br />
          <select name="have_glasses" id="have_glasses" value={haveGlasses} onChange={(e) => setHaveGlasses(e.target.value)}>
            <option value="0">{t('No')}</option>
            <option value="1">{t('Yes')}</option>
          </select>
        </div>
      </div>

      <button type="submit" className="fz-1-banner-btn fz-comment-form__btn">
        {t('Send')}
      </button>
    </form>
  );
};

export default BookingFormSection;