import React, { createContext, useState } from "react";

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("SAR");
  const [value, setValue] = useState(1); // Default value for SAR

  const currencyRates = {
    SAR: 1,
    USD: 0.27,
    Euro: 0.25,
    Pound: 0.21
  }; 

  const changeCurrency = (currency) => {
    setSelectedCurrency(currency);
    setValue(currencyRates[currency]);
  };

  return (
    <CurrencyContext.Provider value={{ selectedCurrency, value, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};