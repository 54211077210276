import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';

 
const CustomFlagDropdown = () => {

  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en'); 

  // const handleLanguageChange = (lang) => {
  //   i18n.changeLanguage(lang);
  // };
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };

  const [selectedOption, setSelectedOption] = useState("0");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    { value: "0", label: "English", flag: "/assets/images/us.png", code: "en" },
    { value: "1", label: "العربية", flag: "/assets/images/sa.png", code: "ar" },
    { value: "2", label: "Français", flag: "/assets/images/fr.jpg", code: "fr" },
    { value: "3", label: "Español", flag: "/assets/images/es.jpg", code: "es" },
    { value: "4", label: "Deutsch", flag: "/assets/images/gr.jpg", code: "de" },
    { value: "5", label: "Русский", flag: "/assets/images/ru.jpg", code: "ru" },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isRtl = selectedOption === "1"; // Arabic language selected


  return (
    <div
    className={`custom-dropdown ${isRtl ? "rtl " : ""}`} // Add "rtl" class if Arabic language is selected
    ref={dropdownRef}  id="google_translate_element"
  >      <div
        className={`dropdown-toggle custom-dropdown-toggle ${
          dropdownOpen ? "open" : ""
        }`}
        onClick={toggleDropdown}
       
      >
        <span>
          <img
            src={
              options.find((option) => option.value === selectedOption)?.flag
            }
            alt=""
          />
        </span>
        <span>
          {options.find((option) => option.value === selectedOption)?.label}
        </span>
      </div>
      <div className={`custom-dropdown-menu ${dropdownOpen ? "show" : ""}`}>
        {options.map((option) => (
          <div
            key={option.value}
            className={`dropdown-item ${
              selectedOption === option.value ? "selected" : ""
            }`}
            onClick={() => {
              handleLanguageChange(option.code);
              handleOptionClick(option.value);
            }}
              >
            <img src={option.flag} alt="" />
            <span>{option.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomFlagDropdown;
