import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderNav from "../navigation/HeaderNav";
import { Link, useNavigate } from "react-router-dom";
import CartModal from "../modal/CartModal";
import WishlistModal from "../modal/WishlistModal";
import { FarzaaContext } from "../../context/FarzaaContext";
import CustomFlagDropdown from "../utils/CustomFlagDropdown";
import axios from "axios";
import { useTranslation } from 'react-i18next'; 
import { AuthContext } from "../authentication/AuthContext";
 import { StoreContext } from "../../context/StoreContext";
 import CustomCurrency from "../utils/CustomCurrency";

 import { Helmet } from "react-helmet";  // Import Helmet for managing the head

 const apiUrl = import.meta.env.VITE_API_URL;

const HeaderSection6 = () => { 

  const { t } = useTranslation();
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  const { cart, getCartQuantity } = useContext(StoreContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate(); // Initialize navigate


  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(`${apiUrl}/api/products/search`);
      setProducts(response.data);
    };
    fetchProducts();
  }, []);

  const {
    isHeaderFixed,
    handleWishlistShow,
    handleCartShow,
    wishlist,
    handleQuantityChange,
    handleRemoveItem,
    handleRemoveItemWishlist,
  } = useContext(FarzaaContext);
  const [activeActions, setActiveActions] = useState(false);
  const [activeSearchbar, setActiveSearchbar] = useState(false);

  
  const [footers, setFooters] = useState({});
  
  useEffect(() => {
    axios.get(`${apiUrl}/api/footers`)
     .then(response => {
        setFooters(response.data[0]);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);

  const toggleActions = () => {
    setActiveActions(!activeActions);
    if (activeSearchbar) {
      setActiveSearchbar(false);
    } else {
      return;
    }
  };
  const toggleSearchbar = () => {
    setActiveSearchbar(!activeSearchbar);
    if (activeActions) {
      setActiveActions(false);
    } else {
      return;
    }
  };
  // Refs for the elements you want to track clicks outside of
  const actionsRef = useRef(null);

  // Function to handle clicks outside of the actions element
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setActiveActions(false);
    }
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setActiveSearchbar(false);
    }
  };

  // Effect to add event listener when component mounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
    <header className="fz-header-section fz-5-header-section">
      <div className="top-header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <p>{t('SALE: 400+ products up to 70% off. Shop Now')}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`to-be-fixed ${isHeaderFixed ? "fixed" : ""}`}
        ref={actionsRef}
      >
        <div className="middle-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-4">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-6">
                    <div className="logo">
                      <Link to="/">
                        <img src={`${apiUrl}/uploads/${footers.logo}`} alt="logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-9 d-xl-block d-none">
                    <div className="fz-5-header-txt">
                      <ul className="d-flex">
                        <li>
                        {t('call_us')}{" "}
                          <a href={`callto:${footers.phone}`}>{footers.phone}</a>
                        </li>
                        <li>{t('Sat-Thu 10AM - 12AM')}</li>
                        <li>{t('Fri 4PM - 12AM')}</li>

                      </ul>
                    </div>
                  </div>
                  <div className="col-6 d-lg-none">
                    <div className="d-flex gap-4 justify-content-end">
                      <button id="openSearch" onClick={toggleSearchbar}>
                        <i className="fa-regular fa-magnifying-glass"></i>
                      </button>
                      <button id="openInfo" onClick={toggleActions}>
                        <i className="fa-regular fa-grid-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4">
                <div className="header-search">
                  {/* <form className={activeSearchbar ? "active" : ""}>
                    <input type="search" name="keyword" />
                    <button>
                      <i className="fa-regular fa-magnifying-glass"></i>
                    </button>
                  </form> */}
                      <form className={activeSearchbar ? "active" : ""} onSubmit={(e) => {
      e.preventDefault(); // Prevent the default form submission
      navigate(`/shop/search?search=${searchTerm}`); // Navigate to the search page
    }}>
      <input
        type="search"
        name="keyword"
        value={searchTerm} // Bind the input value to searchTerm state
        onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
        placeholder={t('Search...')} // Optional: Add a placeholder
      />
      <button type="submit">
        <i className="fa-regular fa-magnifying-glass"></i>
      </button>
    </form>

                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <ul
                  className={`header-action ${activeActions ? "active" : ""}`}
                >
<li>
  {isLoggedIn? ( 
                      <div className="d-flex p-2">
                      <Link to="/account" className="p-1">
                        <i className="fa-regular fa-user-vneck me-2 fz-dropdown"></i> {t('My Account')}
                      </Link>
                      <button onClick={handleLogout} className="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                          <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                        </svg>
                        {t('Logout')}
                      </button>
                    </div>
    ) : (
      <Link to="/login">
        <i className="fa-regular fa-user-vneck me-2"></i> {t('Sign In')}
      </Link>
    )}  
   

</li>
{isLoggedIn? (
  <>
    <li>
      <a
        role="button"
        className="fz-header-wishlist-btn"
        onClick={handleWishlistShow}
      >
        <i className="fa-regular fa-heart"></i>
      </a>
    </li>
    <li>
      <a
        role="button"
        className="fz-header-cart-btn"
        onClick={handleCartShow}
      >
        <span className="badge">{getCartQuantity()}</span>
        <i className="fa-regular fa-cart-shopping"></i>
      </a>
    </li>
  </>
) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-header d-lg-block d-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-9 col-xl-8 col-lg-7 header-nav-container">
                <HeaderNav position="fz-4-menu" downArrow />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3">
                <div className="select-lang d-flex gap-4 justify-content-end align-items-center">
                  <a href="/track-order" className="order-track">
                    {t('Track Order')}
                  </a>
                  <CustomCurrency />
                  <CustomFlagDropdown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WishlistModal
        wishlistArray={wishlist}
        removeItem={handleRemoveItemWishlist}
      />
      <CartModal
        cartArray={cart}
        remove={handleRemoveItem}
        quantity={handleQuantityChange}
      />
    </header>
    </>
  );
};

export default HeaderSection6;
