import React from "react";
import { useTranslation } from 'react-i18next';


const MessageSection = () => {
  const { t } = useTranslation();

  return (
    <div className="fz-about-area">
      <div className="container">
        <div className="row gy-5">
          <div className="col-xl-6">
            <div className="fz-about-images">
              <div className="row g-0">
                <div className="col-sm-8 col-6">
                  <div className="fz-about-images-left">
                    <div className="fz-about-images-left-img">
                      <img src="assets/images/message-img-1.jpg" alt="about1-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="fz-about-right-content">
              <h4 className="fz-about-right-title">{t("Our Message")}</h4>
              <p className="fz-about-right-descr">
                {t("Message_decription")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSection;



