import React from "react";
import Layout from "../components/layout/Layout";
import ShippingMethodtMain from "../components/main/ShippingMethodtMain";


const ShippingMethod = () => {
  return (
    <Layout>
      <ShippingMethodtMain />
    </Layout>
  );
};

export default ShippingMethod;
