import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import ShippingAddressSection from '../checkout/ShippingAddressSection';

import { useTranslation } from 'react-i18next'

const ShippingAddressMain = () => {
    const { t } = useTranslation();
  return (
    <>
    <BreadcrumbSection title={t('Shipping address')} current={t('Shipping address')} />
    <ShippingAddressSection/>
    </>
  )
}

export default ShippingAddressMain;