import React from "react";
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <div className="fz-1-features-section fz-4-features-section">
      <div className="container">
        <div className="features-container align-items-center">
          <div className="fz-single-feature">
            <div className="fz-single-feature__img">
              <img src="assets/images/fz-4-feature-1.png" alt="Feature Icon" />
            </div>
            <div className="fz-single-feature__txt">
              <h4 className="fz-single-feature__title">{t("Fast Delivery")} </h4>
              <h6 className="fz-single-feature__sub-title"></h6>
            </div>
          </div>

          {/* <div className="fz-single-feature">
            <div className="fz-single-feature__img">
              <img src="assets/images/fz-4-feature-2.png" alt="Feature Icon" />
            </div>
            <div className="fz-single-feature__txt">
              <h4 className="fz-single-feature__title">{t("Get Feedback")} </h4>
              <h6 className="fz-single-feature__sub-title"></h6>
            </div>
          </div> */}

          <div className="fz-single-feature">
            <div className="fz-single-feature__img">
              <img src="assets/images/fz-4-feature-3.png" alt="Feature Icon" />
            </div>
            <div className="fz-single-feature__txt">
              <h4 className="fz-single-feature__title">{t("14 Days Returns")} </h4>
              <h6 className="fz-single-feature__sub-title"></h6>
            </div>
          </div>

          <div className="fz-single-feature">
            <div className="fz-single-feature__img">
              <img src="assets/images/fz-4-feature-4.png" alt="Feature Icon" />
            </div>
            <div className="fz-single-feature__txt">
              <h4 className="fz-single-feature__title">{t("Secure Payment")} </h4>
              <h6 className="fz-single-feature__sub-title"></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
