import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FarzaaContext } from '../../context/FarzaaContext';

const ProductViewFilter = ({ onFilter }) => {
    const { t } = useTranslation();
    const {
        isListView,
        setListView,
        setGridView,
        currentPage,
        productsPerPage,
        totalProducts,
    } = useContext(FarzaaContext);
    const startItem = (currentPage - 1) * productsPerPage + 1;
    const endItem = Math.min(currentPage * productsPerPage, totalProducts);
    const [filterQuery, setFilterQuery] = useState('');
    const [sortBy, setSortBy] = useState('');

    const handleFilterChange = (e) => {
        const query = e.target.value;
        setFilterQuery(query);
        onFilter(query, sortBy);
    };

    const handleSortChange = (e) => {
        const sortOption = e.target.value;
        setSortBy(sortOption);
        onFilter(filterQuery, sortOption);
    };

    return (
        <div className="product-view-actions">
            <div className="row gy-3 align-items-center">
                <div className="col-xxl-6 col-xl-6 col-lg-5 col-6 col-xxs-12 text-center text-md-start">
                    <p className="text-center text-sm-start">
                        {t('Showing')} {startItem}-{endItem} {t('of')} {totalProducts} {t('results')}
                    </p>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-7 col-6 col-xxs-12 col-sm-6">
                    <div className="product-view-right-actions text-center text-md-end d-flex justify-content-center justify-content-md-end">
                        <div className="view-type">
                            <button className={`grid-view ${isListView ? '' : 'active'}`} onClick={setGridView}>
                                <i className="fa-solid fa-grid-2"></i>
                            </button>

                            <button className={`list-view ${isListView ? 'active' : ''}`} onClick={setListView}>
                                <i className="fa-light fa-list"></i>
                            </button>
                        </div>

                        <div className="product-filtering d-inline-block">
                            <Form.Control
                                type="text"
                                placeholder={t('Search by name or price')}
                                value={filterQuery}
                                onChange={handleFilterChange}
                                style={{ marginRight: '10px' }}
                            />
                        </div>

                        <div className="product-sorting d-inline-block">
                            <Form.Select className="nice-select" onChange={handleSortChange} value={sortBy}>
                                <option value="">{t('Default')}</option>
                                <option value="name-az">{t('By Name (A to Z)')}</option>
                                <option value="name-za">{t('By Name (Z to A)')}</option>
                                <option value="price-low-high">{t('By Price (Low to High)')}</option>
                                <option value="price-high-low">{t('By Price (High to Low)')}</option>
                            </Form.Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductViewFilter;
