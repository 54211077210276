import React, { useContext } from 'react';
import WishlistItemTable from './WishlistItemTable';
import { FarzaaContext } from '../../context/FarzaaContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../context/StoreContext';


const WishlistSection = () => {
    const { t } = useTranslation();
    const {
        handleQuantityChangeWishlist,
        addWishlistToCart
    } = useContext(FarzaaContext)
    const { wishlist } = useContext(StoreContext);

  return (
    <div className="container">
        <div className="cart-section wishlist-section">
            <div className="cart-left wishlist-inner-section">
                <div className="cart-area">
                    <div className="cart__body">
                        <div className="table-responsive">
                        <WishlistItemTable wishlistArray={wishlist} />

                            {/* <WishlistItemTable wishlistArray={wishlist} removeItem={removeFromWishlist} quantity={handleQuantityChangeWishlist}/> */}
                        </div>
                        <div className="cart-left-actions d-flex justify-content-end">
                            {wishlist.length === 0 ? (
                                <Link className="fz-1-banner-btn update-cart-btn" to='/shop'>{t('Go to Shop')}</Link>  
                            ):(
                              <button type="submit" className="fz-1-banner-btn update-cart-btn" onClick={addWishlistToCart}>{t('Add all product to cart')}</button>  
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WishlistSection