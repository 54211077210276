import React, { useState, useEffect, useContext } from "react";
import { FarzaaContext } from "../../context/FarzaaContext";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const BlogTagFilter = () => {
  const [t, i18n] = useTranslation();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    axios.get("http://localhost:8000/api/tags")
     .then(response => {
      setTags(response.data);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);

  
  const { selectedBlogTags, handleBlogTagSelection } =
    useContext(FarzaaContext);
  return (
    <section className="sidebar-single-area product-tags-area">
      <h3 className="sidebar-single-area__title">{t('Blog tags')}</h3>
      <div className="tags">
        {tags.map((tag) => (
          <a
            key={tag}
            className={selectedBlogTags.includes(tag) ? "active" : ""}
            onClick={() => handleBlogTagSelection(tag)}
          >
            {i18n.language === 'en' ? tag.name_en : tag.name_ar}
          </a>
        ))}
      </div>
    </section>
  );
};

export default BlogTagFilter;
