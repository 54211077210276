import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;


const ContactFormSection = () => {
  const { t } = useTranslation(); 

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !message) {
      
      toast.error(t('Please fill out all fields.'), { position: 'top-right' });
    } else if (!isValidEmail(email)) {
      toast.warning(t('Please provide a valid email address.'), { position: 'top-right' });
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('message', message);

      fetch(`${apiUrl}/api/add-contact`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            toast.success(t('Form submitted successfully!'), { position: 'top-right' });
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
          } else {
            toast.success(t('Form submitted successfully!'), { position: 'top-right' });

            // toast.error('Error submitting form.', { position: 'top-right' });
          }
        })
        .catch((error) => {
          toast.error(t('Error submitting form.'), { position: 'top-right' });
        });
    }
  };

  const isValidEmail = (email) => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  return (
    <form action="#" onSubmit={handleFormSubmit}>
      <div className="row g-xl-4 g-3">
        <div className="col-12 col-xxs-12">
          <input
            type="text"
            name="commenter-name"
            id="commenter-name"
            placeholder={t('Full Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="col-6 col-xxs-12">
          <input
            type="email"
            name="commenter-email"
            id="commenter-email"
            placeholder={t('Email Address')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-6 col-xxs-12">
          <input
            type="number"
            name="commenter-number"
            id="commenter-number"
            placeholder={t('Phone Number')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="col-12">
          <textarea
            name="commenter-comment"
            id="commenter-comment"
            placeholder={t('Your Message')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button type="submit" className="fz-1-banner-btn fz-comment-form__btn">
        {t('Send Message')}
      </button>
    </form>
  );
};

export default ContactFormSection;
