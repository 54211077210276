import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessImage from "../../public/assets/images/success-order.png"
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'; 


const ThankYou = () => {
    const { t } = useTranslation();
  return (
    <Layout>
   <section className="fz-account-form-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="text-center">
            <img src={SuccessImage} alt="" width={100} height={100} />
            <h1>{t('Thank You for Your Order!')}</h1>
            <p>{t("Your order has been successfully placed. We appreciate your business and are thrilled that you've chosen to shop with us.")}</p>

            <p>{t("We'll send you an email with your order confirmation and details shortly. If you have any questions or concerns, please don't hesitate to reach out to us")}</p>
            <p>{t("Thank you again for your business. We look forward to serving you in the future!")}</p>
            <button className="btn btn-dark mt-2">
  <Link to="/shop">{t("Continue Shopping")}</Link>
</button>
          </div>
        </div>
      </div>
    </div>
    </section>
    </Layout>

  );
};

export default ThankYou;