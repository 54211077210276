import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import CheckoutSection from '../checkout/CheckoutSection';
import { useTranslation } from 'react-i18next';

const CheckoutMain = () => {
  const { t } = useTranslation();
  return (
    <>
    <BreadcrumbSection title={t('Checkout')} current={t('Checkout')} />
    <CheckoutSection/>
    </>
  )
}

export default CheckoutMain