import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import WishlistSection from '../wishlist/WishlistSection';
import { useTranslation } from 'react-i18next';

const WishListMain = () => {
  const { t } = useTranslation();
  return (
    <>
        <BreadcrumbSection title={t('Wishlist')} current={t('Wishlist')} />
        <WishlistSection/>
    </>
  )
}

export default WishListMain