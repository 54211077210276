import React from "react";
import HeaderSection6 from "../header/HeaderSection6";
import FooterSection from "../footer/FooterSection";
import RightSideBar from "../sidebar/RightSideBar";
import BottomMobileMenu from "../navigation/BottomMobileMenu";

const Layout = ({ children }) => {
  return (
    <>
      <HeaderSection6 />
      {children}
      <RightSideBar />
      <FooterSection logo="assets/images/logo-1.png" />
      <BottomMobileMenu
        style="fz-5-mobile-menu"
        logo="assets/images/logo-5.png"
      />

    </>
  );
};

export default Layout;
