import React from 'react'
import Layout from '../components/layout/Layout'
import ForgetPasswordMain from '../components/main/ForgetPasswordMain'

const ForgetPassword = () => {
  return (
    <Layout>
        <ForgetPasswordMain/>
    </Layout>
  )
}

export default ForgetPassword