import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locale/en.json";
import translationAR from "./locale/ar.json";
import translationFR from "./locale/fr.json";
import translationDE from "./locale/de.json";
import translationES from "./locale/es.json";
import translationRU from "./locale/ru.json";
import LanguageDetector from 'i18next-browser-languagedetector';




const resources ={
    en: {
        translation: translationEN
      },
      ar: {
      translation: translationAR
    },
    es: {
    translation: translationES
  },
  fr: {
  translation: translationFR
},
de: {
translation: translationDE
},
ru: {
  translation: translationRU
},
}


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en', // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
     escapeValue: false // react already safes from xss
    }
  });
  
  export default i18n;