import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;

const HeaderNav = ({ position, downArrow }) => {

  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

 
 
  useEffect(() => {
    axios.get(`${apiUrl}/api/categories`)
     .then(response => {
      setCategories(response.data);
      })
     .catch(error => {
        console.error(error);
      });
      axios.get(`${apiUrl}/api/subcategories`)
      .then(response => {
        setSubcategories(response.data);
       })
      .catch(error => {
         console.error(error);
       });
  }, []);
  const getCategorySubcategories = (categoryId) => {
    return subcategories.filter(subcategory => subcategory.category_id === categoryId);
  };

  return (
    <nav className="fz-header-nav">
      <ul className={`align-items-center ${position}`}>
      <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            {t('Home')}
          </Link>
        </li>
        <li className="fz-nav-item">
          <Link to="/shop" className="fz-nav-link">
          {t('shop')}
          </Link>
        </li>
        {categories.map((category, index) => {
          const categorySubcategories = getCategorySubcategories(category.id);
          return (
            <li
              className={`fz-nav-item ${categorySubcategories.length > 0 ? 'fz-dropdown' : ''}`}
              key={index}
            >
              <Link to={`/products/category/${category.slug}`}  className="fz-nav-link">
                {i18n.language === 'ar' ? category.name_ar : category.name_en}
              </Link>
              {categorySubcategories.length > 0 && (
                <ul className="fz-submenu">
                  {categorySubcategories.map((subcategory, index) => (
                    <li key={index}>
                      <Link to={`/products/subcategory/${subcategory.slug}`} className="fz-nav-link fz-submenu-nav-link">
                        {i18n.language === 'ar' ? subcategory.name_ar : subcategory.name_en}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
        <li className="fz-dropdown fz-nav-item">
          <a role="button" className="fz-nav-link">
            <span>{t('pages')}</span>{" "}
            <i
              className={
                downArrow ? "fa-solid fa-angle-down" : "fa-regular fa-plus"
              }
            ></i>
          </a>

          <ul className="fz-submenu">
            <li>
              <Link to="/about" className="fz-nav-link fz-submenu-nav-link">
                {t('About')}
              </Link>
            </li>
            <li>
              <Link to="/faq" className="fz-nav-link fz-submenu-nav-link">
                {t('FAQ')}
              </Link>
            </li>
            <li>
              <Link to="/blog" className="fz-nav-link fz-submenu-nav-link">
                {t('blogs')}
              </Link>
            </li>
            <li>
              <Link to="/appointment" className="fz-nav-link fz-submenu-nav-link">
                {t('Book Appointment')}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="fz-nav-link fz-submenu-nav-link">
                {t('Contact')}
              </Link>
            </li>


          </ul>
        </li>

      </ul>
    </nav>
  );
};

export default HeaderNav;
