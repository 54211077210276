import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;
const ProductCategoryList2 = () => {
  const [activeCat, setActiveCat] = useState({});
  const [t, i18n] = useTranslation();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
 
  useEffect(() => {
    axios.get(`${apiUrl}/api/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error(error);
      });
    axios.get(`${apiUrl}/api/subcategories`)
      .then(response => {
        setSubcategories(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const getCategorySubcategories = (categoryId) => {
    return subcategories.filter(subcategory => subcategory.category_id === categoryId);
  };

  const toggleActiveCat = (category) => {
    setActiveCat((prevState) => ({
      ...prevState, // Copy the previous state
      [category.id]: !prevState[category.id], // Toggle the specified category
    }));
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Category')}</h3>
      <ul className="product-categories categories">
        {categories.map((category, index) => (
          <li className="cat-item" key={index}>
            <Link
              className={`has-sub ${activeCat[category.id] ? "open" : ""}`}
              role="button"
              to={`/products/category/${category.slug}`}
              onClick={() => toggleActiveCat(category)}
            >
              {i18n.language === 'ar' ? category.name_ar : category.name_en}
            </Link>
            <ul
              className={`product-categories ${
                activeCat[category.id] ? "d-block" : "d-none"
              }`}
            >
              {getCategorySubcategories(category.id).map((subcategory) => (
                <li className="cat-item" key={subcategory.id}>
                  <Link to={`/products/subcategory/${subcategory.slug}`}>
                    {i18n.language === 'ar' ? subcategory.name_ar : subcategory.name_en}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductCategoryList2;