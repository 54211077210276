import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection'
import CartSection from '../cart/CartSection'
import { useTranslation } from 'react-i18next'

const CartMain = () => {
  const { t } = useTranslation();
  return (
    <>
        <BreadcrumbSection title={t('Cart')} current={t('Cart')} />
        <CartSection/>
    </>
  )
}

export default CartMain