
import React, { useState, useEffect,useContext  } from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../components/authentication/AuthContext';
const apiUrl = import.meta.env.VITE_API_URL;
function AppleCallback() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const { handleAppleAuth } = useContext(AuthContext);

    useEffect(() => {
        fetch(`${apiUrl}/api/auth/apple/callback${location.search}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
           .then((response) => {
                return response.json();
            })
           .then((data) => {
                setLoading(false);
                setData(data);
                handleAppleAuth(data.access_token);
                navigate('/', { replace: true });
            });
    }, []);

    if (loading) {
        return <DisplayLoading />;
    } else {
        return <DisplayData data={data} />;
    }
}

function DisplayLoading() {
    return <div>Loading....</div>;
}

function DisplayData(data) {
    return (
        <div>
            <samp>{JSON.stringify(data, null, 2)}</samp>
        </div>
    );
}

export default AppleCallback;