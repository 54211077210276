import React from 'react';

const LocationSection = () => {
  return (
    <div className="fz-contact-location-map">
      <div className="row">
        <div className="col-md-6 p-2">
          <iframe 
            src=" https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14499.463476649345!2d46.6860683!3d24.6971372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03596fd3806b%3A0x5cf1bfb9150638e2!2zUkVHQUwgRVlFUyDYsdmK2KzZhCDYp9mK2LI!5e0!3m2!1sar!2ssa!4v1719231957949!5m2!1sar!2ssa" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            width="100%"
            height="300"
          ></iframe>
        </div>
        <div className="col-md-6 p-2">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d269.4230258279101!2d46.68376228361159!3d24.69563049897159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f038b8a1f2329%3A0x1664ca84fa340969!2sRegal%20Eyes%20Narcissus!5e0!3m2!1sar!2ssa!4v1719232013841!5m2!1sar!2ssa"
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            width="100%"
            height="300"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default LocationSection