import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';


const apiUrl = import.meta.env.VITE_API_URL;

const ProductDetailSlider = () => {
  const [products, setProducts] = useState({});
  const { slug_en } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/product/${slug_en}`);
        const data = response.data;
        // console.log('Products data:', data);
        setProducts(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
  }, [slug_en, i18n.language]);

  const images = [
    {
      original: products.image
        ? `${apiUrl}/uploads/${products.image}`
        : '',
      thumbnail: products.image
        ? `${apiUrl}/uploads/${products.image}`
        : '',
    },
    ...(products.photos
      ? products.photos.split(',').map((image) => ({
          original: `${apiUrl}/uploads/${image.trim()}`,
          thumbnail: `${apiUrl}/uploads/${image.trim()}`,
        }))
      : []),
  ];

  return (
    <ImageGallery
      items={images.filter((image) => image.original)}
      showThumbnails={images.length > 1}
      showFullscreenButton={false}
      showPlayButton={false}
    />
  );
};

export default ProductDetailSlider;