import React from 'react'
import SearchFilter from './SearchFilter'
import ProductCategoryList2 from './ProductCategoryList2';
import GenderSelectFilter from './GenderSelectFilter';
import FrameShapeFilter from './FrameShapeFilter';
import FrameBrandFilter from './FrameBrandFilter';
import ProductPriceFilter from './ProductPriceFilter';
import ProductViewFilter from './ProductViewFilter';
import ProductContainer2 from './ProductContainer2';
import ProductPagination from './ProductPagination';
import { useSearchParams, useParams } from 'react-router-dom';
 

const ShopAreaSection3 = ({ sidebarRef, active }) => {
        const [searchParams] = useSearchParams();
        const shapeId = searchParams.get("shape_id");
        const genderId = searchParams.get("gender_id");
        const brandId = searchParams.get("brand_id");
        const { slug } = useParams(); 

      


  return (
    <div className="shop-area">
        <div className="container">
            <div className="row gy-5 justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8 col-9 col-xxs-12 order-1 order-lg-0">
                    <div
        className={`fz-sidebar fz-sidebar-2 ${active ? "active" : ""}`}
        ref={sidebarRef}
      >
                         <SearchFilter/>    
                        <ProductCategoryList2 />  
                        <GenderSelectFilter /> 
                        <FrameShapeFilter />   
                        <FrameBrandFilter />   
                        <ProductPriceFilter/>  
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 order-0 order-lg-1">
                    <ProductViewFilter/>   
                    {/* <ProductContainer2 shapeId={shapeId} genderId={genderId} brandId={brandId} />    */}
                    <ProductContainer2 
                     slug={slug} 
            shapeId={shapeId} 
            genderId={genderId} 
            brandId={brandId} 
          />

                </div>
            </div>
        </div>
    </div>
  )
}

export default ShopAreaSection3

