import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const apiUrl = import.meta.env.VITE_API_URL;

const BlogSection5 = () => {


  const [blogs, setBlogs] = useState([]);
  const { t, i18n } = useTranslation();



  useEffect(() => {
    axios.get(`${apiUrl}/api/blogs`)
     .then(response => {
      setBlogs(response.data);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);
  return (


    <section className="fz-5-blog-section pt-120">
      <div className="container">
        <div className="fz-5-section-heading fz-5-section-heading-2">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <div className="section-heading__txt text-center">
                <h2 className="fz-section-title">{t('Our blog')}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4 justify-content-center">

        {blogs.map((blog, index) => (
          <div className="col-lg-4 col-md-6 col-sm-10" key={index}>
            <div className="fz-5-blog-card">
              <div className="fz-5-blog-card-img">
                         
                <img src={`${apiUrl}/uploads/${blog.image}`} alt="image" />
              </div>
              <div className="fz-5-blog-card-txt">
                <h3 className="fz-5-blog-card-title">
                  <Link to={`/api/blog/${blog.slug_en}`}>
                  {blog.title_en}
                  </Link>
                </h3>
                <p>
                {blog.description_en.split(' ').slice(0, 20).join(' ')}...
                </p>
              </div>
            </div>
          </div>
         ))}


        </div>
      </div>
    </section>
  
  );
};

export default BlogSection5;
