import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import AuthenticationSignupSection from '../authentication/AuthenticationSignupSection';
import { useTranslation } from 'react-i18next';

const SignUpMain = () => {
  const { t } = useTranslation();
  return (
    <>
        <BreadcrumbSection title={t('SignUp')} current={t('SignUp')} />
        <AuthenticationSignupSection/>
    </>
  )
}

export default SignUpMain