import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../authentication/AuthContext';
import axios from 'axios';
const apiUrl = import.meta.env.VITE_API_URL;
const ForgetPasswordMain = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSendResetLinkEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/forgot-password`, { email });
      if (response.status === 200) {
        toast.success('Succseful send email to Reset !', { position: 'top-right' });
        setShowResetPasswordForm(true);
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error('Not found email.', { position: 'top-right' });
      } else {
        toast.error('Error sending reset password email.', { position: 'top-right' });
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/api/forget-password`, { otp, email, password: newPassword, password_confirmation: confirmPassword });
      if (response.status === 200) {
        toast.success('Password reset successfully!', { position: 'top-right' });
        // Redirect to login page or perform any other action
      }
    } catch (error) {
      toast.error('Error resetting password.', { position: 'top-right' });
    }
  };

  return (
    <>
      <BreadcrumbSection title={t('Forget Password')} current={t('Forget Password')} />
      <section className="fz-account-form-section">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-9 col-10 col-xxs-12">
              <h3 className="single-form-title">{t('Forget Password')}</h3>
              <form action="#" onSubmit={handleSendResetLinkEmail}>
                <input
                  type="email"
                  name="email"
                  id="login-email"
                  placeholder={t('Email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit" className="fz-1-banner-btn single-form-btn">{t('Reset')}</button>
              </form>
              {showResetPasswordForm && (
                <form action="#" onSubmit={handleResetPassword}>

                  <input
                    type="text"
                    name="otp"
                    id="otp"
                    placeholder={t('OTP')}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <input
                    type="password"
                    name="new_password"
                    id="new-password"
                    placeholder={t('New Password')}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <input
                    type="password"
                    name="confirm_password"
                    id="confirm-password"
                    placeholder={t('Confirm Password')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button type="submit" className="fz-1-banner-btn single-form-btn">{t('Reset Password')}</button>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPasswordMain;