import React from 'react';
import MyAccountSection from './MyAccountSection';
import { useTranslation } from 'react-i18next'


const AuthenticationSection = () => {
  const { t } = useTranslation();
  return (
    <section className="fz-account-form-section">
        <div className="container">
            <div className="row g-4 justify-content-center">
                <div className="col-lg-12 col-md-8 col-sm-9 col-10 col-xxs-12">
                    <h3 className="single-form-title">{t('My Account')} </h3>
                    <MyAccountSection/>

                </div>

            </div>
        </div>
    </section>
  )
}

export default AuthenticationSection