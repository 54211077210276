import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL;
const BrandSection2 = () => {

  
  const [brands, setBrands] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`${apiUrl}/api/brands`)
     .then(response => {
      setBrands(response.data);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div className="clients-section fz-1-brands-section p-0">
      <div className="container">
        <div className="fz-5-section-heading fz-5-section-heading-2">
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <div className="section-heading__txt text-center">
                <h2 className="fz-section-title">{t('Our Brands')}</h2>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          className="clients-2 fz-5-brands"
          slidesPerView={6}
          spaceBetween={15}
          autoplay={true}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 3,
            },

            480: {
              slidesPerView: 4,
            },

            768: {
              slidesPerView: 5,
            },

            1200: {
              slidesPerView: 6,
            },
          }}
          modules={[Autoplay]}
        >
          {brands.map((brand, index) => (
            <SwiperSlide key={index}>
              <Link to={`/shop/search?brand_id=${brand.id}`}>
              <img src={`${apiUrl}/uploads/${brand.image}`} alt={brand.name_en} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BrandSection2;
