import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const apiUrl = import.meta.env.VITE_API_URL;

const FrameShapeFilter = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate(); 

  const [shapes, setShapes] = useState([]);
  const [filteredShapes, setFilteredShapes] = useState([]);

  useEffect(() => {
    // Fetch all shapes
    axios.get(`${apiUrl}/api/shapes`)
      .then(shapeResponse => {
        const allShapes = shapeResponse.data.reduce((acc, shape) => {
          acc[shape.id] = {
            id: shape.id,
            title: i18n.language === 'ar' ? shape.title_ar : shape.title_en,
            image: shape.image
          };
          return acc;
        }, {});

        setShapes(allShapes);
        setFilteredShapes(Object.values(allShapes)); // Set all shapes as filtered shapes
      })
      .catch(error => {
        console.error("Error fetching shapes:", error);
      });
  }, [i18n.language]);

  const handleShapeClick = (shapeId) => {
    navigate(`/shop/search?shape_id=${shapeId}`);
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Frame Shape')}</h3>
      <ul className="frame-shape">
        <li className="shape-item">
          <a href="/shop">
            <div className="fz-shape-txt">
              <span>{t('All')}</span>
            </div>
          </a>
        </li>
        {filteredShapes.map((shape) => (
          <li className="shape-item" key={shape.id}>
            <a href="#" onClick={(e) => { e.preventDefault(); handleShapeClick(shape.id); }}>
              <div className="fz-shape-img">
                <img src={`${apiUrl}/uploads/${shape.image || ''}`} alt="image" />
              </div>
              <div className="fz-shape-txt">
                <span>{shape.title || ''}</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FrameShapeFilter;