import { Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ProductPriceFilter = ({setSearchProducts}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(30000); // Default max price
    const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);
    const [filteredProducts, setFilteredProducts] = useState([]); // State for filtered products
    const apiUrl = import.meta.env.VITE_API_URL; 
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchPriceRange = async () => {
            setLoading(true); // Set loading to true
            try {
                const response = await axios.get(`${apiUrl}/api/products/getPriceRange`); // Corrected endpoint
                console.log('Price Range Response:', response.data);
        
                const { min_price, max_price } = response.data;
        
                setMinPrice(Number(min_price));
                setMaxPrice(Number(max_price));
                setPriceRange([Number(min_price), Number(max_price)]);
                setError(null);
            } catch (error) {
                console.error("Error fetching price range:", error);
                setError(error.message);
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        fetchPriceRange();
    }, [apiUrl]);

    const applyPriceFilter = async () => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            price_min: priceRange[0],
            price_max: priceRange[1],
        });

        // Fetch filtered products based on the selected price range
        await fetchFilteredProducts(priceRange[0], priceRange[1]);
    };

    const fetchFilteredProducts = async (priceMin, priceMax) => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get(`${apiUrl}/api/products/searchByPrice`, {
                params: {
                    price_min: priceMin,
                    price_max: priceMax,
                },
            });
            console.log('Filtered Products Response:', response.data);
            setSearchProducts(response.data); // Pass the filtered products to the parent

            setError(null);
        } catch (error) {
            console.error("Error fetching filtered products:", error);
            setError(error.message);
        } finally {
            setLoading(false); // Set loading to false
        }
    };


    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    return (
        <section className="sidebar-single-area price-filter-area">
            <h3 className="sidebar-single-area__title">{t('Filter by price')}</h3>
            {loading ? (
                <p>{t('Loading...')}</p>
            ) : (
                <>
                    <div className="slider-keypress">
                        <Slider
                            getAriaLabel={() => 'Price range'}
                            value={priceRange}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(selling_price) => `${t('SR')} ${selling_price}`}
                            min={minPrice}
                            max={maxPrice}
                            sx={{
                                color: "#B8860B",
                                '& .MuiSlider-thumb': {
                                    border: '1px solid #B8860B',
                                    color: '#fff',
                                },
                            }}
                        />
                    </div>
                    <div className="price-filter d-flex align-items-center justify-content-between">
                        <div className="filtered-price d-flex align-items-center">
                            <h6 className="filtered-price__title">{t('price:')}</h6>
                            <div className="filtered-price__number">
                                <div className="range-start d-flex align-items-center">
                                    <span className="currency-sign">{t('SR')}</span>
                                    <span className="input -with-keypress-0">{priceRange[0]}</span>
                                </div>
                                <span className="hyphen">-</span> 
                                <div className="range-end d-flex align-items-center">
                                    <span className="currency-sign">{t('SR')}</span>
                                    <span className="input-with-keypress-1">{priceRange[1]}</span>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="filter-price-btn fz-1-banner-btn" onClick={applyPriceFilter}>
                            Filter
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>} 
                </>
            )}
        </section>
    );
};

export default ProductPriceFilter;