import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection'
import BlogContainer from '../blog/BlogContainer'
import { useTranslation } from 'react-i18next';


const BlogMain = () => {
  const { t } = useTranslation();

  return (
    <>
        <BreadcrumbSection title={t('blogs')} current={t('blogs')} />
      
        <BlogContainer/>
    </>
  )
}

export default BlogMain