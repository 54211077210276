
import React, { useState, useEffect, useContext } from 'react';
import ProductDetailAction from './ProductDetailAction';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../context/CurrencyContext';  //Currency 

import axios from 'axios';
import { useParams } from 'react-router-dom';
const apiUrl = import.meta.env.VITE_API_URL;

const ProductDetailTextSection = () => {
  const [product, setProduct] = useState({});
  const { slug_en } = useParams();
  const { t, i18n } = useTranslation();
  const { selectedCurrency, value } = useContext(CurrencyContext); //Currency 
  const [categories, setCategories] = useState({});
  const [footers, setFooters] = useState({});
  const [error, setError] = useState(null); // Define error state
  const [loading, setLoading] = useState(true); // Define loading state

  useEffect(() => {
    fetchProductDetails(slug_en);
  }, [slug_en]);

  const fetchProductDetails = (slug_en) => {
    fetch(`${apiUrl}/api/product/${slug_en}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch product details');
        }
      })
      .then(data => {
        // Remove HTML tags from description_en
        const plainTextDescription = data.description_en.replace(/<[^>]+>/g, '');
        data.description_en = plainTextDescription;
        setProduct(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message); // Set error message
        setLoading(false); // Stop loading
      });

    // fetch category data
    fetchCategories();
    fetchFooter();
  };

  const fetchCategories = () => {
    axios.get(`${apiUrl}/api/categories`)
      .then(response => {
        const categoriesData = response.data.reduce((acc, category) => {
          acc[category.id] = i18n.language === 'ar' ? category.name_ar : category.name_en;
          return acc;
        }, {});
        setCategories(categoriesData);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchFooter = () => {
    axios.get(`${apiUrl}/api/footers`)
      .then(response => {
        setFooters(response.data[0]);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    const tabbyPromo = new TabbyPromo({
      selector: '#TabbyPromo', // required, content of tabby Promo Snippet will be placed in element with that selector.
      currency: 'SAR', // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      price: parseFloat(product.selling_price), // required, price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      installmentsCount: 4, // Optional, for non-standard plans.
      lang: i18n.language === 'ar' ? 'ar' : 'en',
      source: 'product', // Optional, snippet placement; `product` for product page and `cart` for cart page.      
      publicKey: import.meta.env.VITE_TABBY_PUBLIC_KEY,
      merchantCode: import.meta.env.VITE_TABBY_MERCHANT_CODE,
    });
  }, [product, i18n.language]);

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="fz-product-details__txt">
      <h2 className="fz-product-details__title">{i18n.language === 'ar' ? product.title_ar : product.title_en}</h2>
      <div className="fz-product-details__price-rating">
        {/* <span className="price">{t('SR')} {product.selling_price}</span> */}
        <span className="price">{t(selectedCurrency)} {Math.round(product.selling_price * value)}</span>

        <div className="rating">
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
        </div>
      </div>

      <div className="fz-product-details__infos">
        <ul>
          < li><span className="info-property"> {t('SKU')} </span> : <span className="info-value">{product.sku}</span></li>
          <li><span className="info-property"> {t('Category')} </span> : <span className="info-value">{categories[product.category_id]}</span></li>
          <li>
            <span className="info-property">{t('Availability')} :</span>
            <span className="info-value">
              {product.stock == 1 ? t('In stock') : product.stock == 2 ? t('Out of stock') : product.stock}
            </span>
          </li>
        </ul>
      </div>

      <p className="fz-product-details__short-descr">
        {i18n.language === 'ar' ? product.description_ar : product.description_en}
      </p>
      {product.quantity > 0 && <ProductDetailAction product={product} />}


      <div className="fz-product-details__payment-methods">
        {footers.payment_method_images && footers.payment_method_images.split(',').map((image, index) => (
          <img key={index} src={`${apiUrl}/uploads/${image.trim()}`} alt="payment method image" width="30" className="me-2" />
        ))}

        <div id="TabbyPromo" />

        <span className="dialog">{t('Guaranteed safe & secure checkout')}</span>
      </div>
    </div>
  );
}

export default ProductDetailTextSection;