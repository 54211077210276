import React, { useState, useEffect, useContext } from "react";
import { FarzaaContext } from '../../context/FarzaaContext';
import { blogList } from '../../data/Data';
import axios from "axios";
import { useTranslation } from 'react-i18next';


const BlogCategoryFilter = () => {
  const [t, i18n] = useTranslation();


  const [categoryBlogs, setCategoryBlogs] = useState([]);

  useEffect(() => {
    axios.get("http://localhost:8000/api/categoryBlogs")
     .then(response => {
        const categoryBlogsWithEnglishNames = response.data.map(categoryBlog => ({
         ...categoryBlog,
          name_en: categoryBlog.name_en || categoryBlog.name, // fallback to `name` if `name_en` is null or undefined
        }));
        setCategoryBlogs(categoryBlogsWithEnglishNames);
      })
     .catch(error => {
        console.error(error);
      });
  }, []);

    const {
        handleBlogCategoryFilter,
        activeBlogCategory
    } = useContext(FarzaaContext)
    
  return (
    <section className="sidebar-single-area product-categories-area">
        <h3 className="sidebar-single-area__title">{t('Blog categories')}</h3>
        <ul className="product-categories">
        {categoryBlogs.map((categoryBlog) => (
          <a
            key={categoryBlog}
            className={`cat-item ${activeBlogCategory === categoryBlog.name_en ? 'active' : ''}`}
            onClick={() => handleBlogCategoryFilter(categoryBlog)}
          >
            {i18n.language === 'en' ? categoryBlog.name_en : categoryBlog.name_ar}
          </a>
        ))}
          {/* {categoryBlogs.map(categoryBlog => (
              <li
                  key={categoryBlog.name_en}
                  onClick={() => handleBlogCategoryFilter(categoryBlog.name_en)}
                  className={`cat-item ${activeBlogCategory === categoryBlog.name_en ? 'active' : ''}`}
              >
                  {categoryBlog.label} ({categoryBlog.name_en === null ? blogList.length : blogList.filter(product => product.category === categoryBlog.name_en).length})
              </li>
          ))} */}
        </ul>
    </section>
  )
}

export default BlogCategoryFilter