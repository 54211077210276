import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CustomCurrency from "../utils/CustomCurrency";


const apiUrl = import.meta.env.VITE_API_URL;

const MobileMenuSidebar = () => {

  const [selectedLang, setSelectedLang] = useState('en');
  const { t, i18n } = useTranslation();
  const [activeNav, setActiveNav] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [pages, setPages] = useState([]);
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
  };

  const [selectedOption, setSelectedOption] = useState("0");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    { value: "0", label: "English", flag: "/assets/images/us.png", code: "en" },
    { value: "1", label: "العربية", flag: "/assets/images/sa.png", code: "ar" },
    { value: "2", label: "Français", flag: "/assets/images/fr.jpg", code: "fr" },
    { value: "3", label: "Español", flag: "/assets/images/es.jpg", code: "es" },
    { value: "4", label: "Deutsch", flag: "/assets/images/gr.jpg", code: "de" },
    { value: "5", label: "Русский", flag: "/assets/images/ru.jpg", code: "ru" },

  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isRtl = selectedOption === "1"; // Arabic language selected

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/subcategories`);
        setSubcategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
    fetchSubcategories();
  }, []);

  const getCategorySubcategories = (categoryId) => {
    return subcategories.filter((subcategory) => subcategory.category_id === categoryId);
  };

  const toggleNav = (nav) => {
    setActiveNav((prevNav) => (prevNav === nav ? "" : nav));
  };

  return (
    <>
      <ul className="align-items-center fz-4-menu">
        {/* Home Link */}
        <li className="fz-nav-item">
          <Link to="/" className="fz-nav-link">
            {t('Home')}
          </Link>
        </li>

        {/* Categories */}
        {categories.map((category) => {
          const categorySubcategories = getCategorySubcategories(category.id);
          return (
            <li className="fz-dropdown fz-nav-item" key={category.id}>
              <a
                role="button"
                className="fz-nav-link"
                onClick={() => toggleNav(`category-${category.id}`)}
              >
                <span>{i18n.language === 'ar' ? category.name_ar : category.name_en}</span>
                <i className="fa-solid fa-angle-down"></i>
              </a>
              <ul className={`fz-submenu ${activeNav === `category-${category.id}` ? "d-block" : "d-none"}`}>
                {categorySubcategories.map((subcategory) => (
                  <li key={subcategory.id}>
                    <Link to={`/products/subcategory/${subcategory.slug}`} className="fz-nav-link fz-submenu-nav-link">
                      {i18n.language === 'ar' ? subcategory.name_ar : subcategory.name_en}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}

        {/* Pages */}
        <li className="fz-dropdown fz-nav-item">
          <a
            role="button"
            className="fz-nav-link"
            onClick={() => toggleNav("pages")}
          >
            <span>{t('pages')}</span>
            <i className="fa-solid fa-angle-down"></i>
          </a>
          <ul className={`fz-submenu ${activeNav === "pages" ? "d-block" : "d-none"}`}>
            <li>
              <Link to="/about" className="fz-nav-link fz-submenu-nav-link">
                {t('About')}
              </Link>
            </li>
            <li>
              <Link to="/faq" className="fz-nav-link fz-submenu-nav-link">
                {t('FAQ')}
              </Link>
            </li>
            <li>
              <Link to="/blog" className="fz-nav-link fz-submenu-nav-link">
                {t('blogs')}
              </Link>
            </li>
            <li>
              <Link to="/appointment" className="fz-nav-link fz-submenu-nav-link">
                {t('Book Appointment')}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="fz-nav-link fz-submenu-nav-link">
                {t('Contact')}
              </Link>
            </li>
          </ul>
        </li>
        <li className="fz-dropdown fz-nav-item p-2">
        
          <div className="d-flex d-md-none" ref={dropdownRef}>
          <CustomCurrency />
            <div className="dropdown">
              <button
                className="btn btn-light dropdown-toggle d-flex align-items-center"
                type="button"
                id="languageDropdown"
                onClick={toggleDropdown}
                aria-expanded={dropdownOpen}
              >
                <img
                  src={options.find((option) => option.value === selectedOption)?.flag}
                  alt=""
                  style={{ width: '30px', height: '20px', marginRight: '5px', borderRadius: '2px' }} // Small flag size
                />
                <span>
                  {options.find((option) => option.value === selectedOption)?.label}
                </span>
              </button>
              <div className={`dropdown-menu ${dropdownOpen ? "show" : ""}`} aria-labelledby="languageDropdown">
                {options.map((option) => (
                  <button
                    key={option.value}
                    className={`dropdown-item d-flex align-items-center ${
                      selectedOption === option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      handleLanguageChange(option.code);
                      handleOptionClick(option.value);
                    }}
                  >
                    <img src={option.flag} alt="" style={{ width: '30px', height: '20px', marginRight: '5px', borderRadius: '2px' }} />
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default MobileMenuSidebar;