import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
const apiUrl = import.meta.env.VITE_API_URL;
const GenderSelectFilter = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  const [genders, setGenders] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredGenders, setFilteredGenders] = useState([]);
  const [selectedGenderId, setSelectedGenderId] = useState(null);
  const [selectedGenders, setSelectedGenders] = useState({});

  useEffect(() => {
    // Fetch all products
    axios.get(`${apiUrl}/api/products`)
      .then(productResponse => {
     
        setProducts(productResponse.data);

        // Fetch all genders
        axios.get(`${apiUrl}/api/genders`)
          .then(genderResponse => {
            
            const allGenders = genderResponse.data.reduce((acc, gender) => {
              acc[gender.id] = {
                id: gender.id,
                title: i18n.language === 'ar' ? gender.title_ar : gender.title_en,
              };
              return acc;
            }, {});
       

            setGenders(allGenders);

            // Filter genders based on products
            const productGenderIds = new Set(productResponse.data.data.map(product => product.gender_id));
            const availableGenders = Object.values(allGenders).filter(gender => productGenderIds.has(gender.id));
         
            setFilteredGenders(availableGenders);
          })
          .catch(error => {
            console.error("Error fetching genders:", error);
          });
      })
      .catch(error => {
        console.error("Error fetching products:", error);
      });
  }, [i18n.language]);

  const handleGenderClick = (genderId) => {
    setSelectedGenderId(genderId);
    fetchProductsByGender(genderId);
    navigate(`/shop/search?gender_id=${genderId}`);
  };

  const fetchProductsByGender = (genderId) => {
    axios.get(`${apiUrl}/api/products/search?gender_id=${genderId}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error("Error fetching products by gender:", error);
      });
  };

  const handleGenderChange = (genderId) => {
    setSelectedGenders((prevState) => ({
      ...prevState,
      [genderId]: !prevState[genderId],
    }));
  };

  return (
    <div className="sidebar-single-area">
      <h3 className="sidebar-single-area__title">{t('Gender')}</h3>
      <ul className="product-categories">
                  
        {filteredGenders.map((gender) => (
          <li className="cat-item" key={gender.id}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id={gender.id}
                checked={selectedGenders[gender.id]}
                onChange={() => handleGenderClick(gender.id)}
              />
              <label className="form-check-label" htmlFor={gender.id}>
                {gender.title}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GenderSelectFilter;
