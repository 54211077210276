import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection'
import BookingSection from '../contact/BookingSection'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';



const BooktMain = () => {
  const { t, i18n } = useTranslation(); // Destructure i18n here
  const keywords = {
    en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
    ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
  };
  const titles = {
    en: "Regaleyes | Book Apointment",
    ar: "ريجل ايز | حجز موعد"
  };
  const descriptions = { // Fixed typo here
    en: "Book an appointment for an eye exam.",
    ar: "حجز موعد لفحص النظر"
    };

  const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
  const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
  const currentDescription = i18n.language === 'ar' ? descriptions.ar : descriptions.en;


  return (
    <>
                <Helmet>
                <title>{currentTitles}</title>
                <meta name="description" content={currentDescription} />
                <meta name="keywords" content={currentKeywords} />
            </Helmet> 
       <BreadcrumbSection title={t('Booking Page')} current={t('Booking')} />
        <BookingSection/>
    </>
  )
}

export default BooktMain