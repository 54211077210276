// import React, { useState, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../authentication/AuthContext'; 
// import { StoreContext } from '../../context/StoreContext';

// const ProductDetailAction = ({ product }) => {
//     const { t } = useTranslation();
//     const navigate = useNavigate();
//     const { token } = useContext(AuthContext); // Get token from AuthContext
//     const { addToCart, addToWishlist } = useContext(StoreContext); // Get addToCart and addToWishlist functions
//     const defaultQuantity = 1;
//     const [quantity, setQuantity] = useState(defaultQuantity);

//     const handleQuantityChange = (newQuantity) => {
//         // Ensure newQuantity is within the allowed range
//         if (newQuantity >= 1 && newQuantity <= product.quantity) {
//             setQuantity(newQuantity);
//         }
//     };

//     const handleAddToCart = () => {
//         if (!token) {
//             // If user is not logged in, redirect to login
//             navigate('/login');
//         } else {
//             // If user is logged in, add to cart
//             const productToAdd = { ...product, quantity }; // Include quantity
//             addToCart(productToAdd);
//         }
//     };

//     const handleAddToWishlist = () => {
//         if (!token) {
//             // If user is not logged in, redirect to login
//             navigate('/login');
//         } else {
//             // If user is logged in, add to wishlist
//             addToWishlist(product);
//         }
//     };

//     return (
//         <div className="fz-product-details__actions">
//             <div className="fz-product-details__quantity cart-product__quantity">
//                 <button 
//                     className="minus-btn cart-product__minus" 
//                     onClick={() => handleQuantityChange(quantity - 1)}
//                     disabled={quantity <= 1} // Disable if quantity is 1
//                 >
//                     <i className="fa-light fa-minus"></i>
//                 </button>
//                 <input
//                     type="number"
//                     name="product-quantity"
//                     className="cart-product-quantity-input"
//                     value={quantity}
//                     onChange={(e) => {
//                         const value = parseInt(e.target.value);
//                         handleQuantityChange(isNaN(value) ? 1 : value); // Handle NaN case
//                     }} 
//                     min="1"
//                 />
//                 <button 
//                     className="plus-btn cart-product__plus" 
//                     onClick={() => handleQuantityChange(quantity + 1)}
//                     disabled={quantity >= product.quantity} // Disable if quantity is at stock limit
//                 >
//                     <i className="fa-light fa-plus"></i>
//                 </button>
//             </div>
//             <button className="fz-product-details__add-to-cart" onClick={handleAddToCart}>
//                 {t('Add to Cart')}
//             </button>
//             <button className="fz-product-details__add-to-wishlist" onClick={handleAddToWishlist}>
//                 <i className="fa-light fa-heart"></i>
//             </button>
//             <button className="fz-product-details__add-to-cart">
//                 {t('Try On')}
//             </button>
//         </div>
//     );
// };

// export default ProductDetailAction;

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../authentication/AuthContext'; 
import { StoreContext } from '../../context/StoreContext';
import VirtualTryOn from '../shop/TryOn'; // Import the Try-On Component
import IconTryOn from "../../../public/assets/images/icon_try-on.svg";
const ApiUrl = import.meta.env.VITE_API_URL;

const ProductDetailAction = ({ product }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useContext(AuthContext); // Get token from AuthContext
    const { addToCart, addToWishlist } = useContext(StoreContext); // Get addToCart and addToWishlist functions
    const defaultQuantity = 1;
    const [quantity, setQuantity] = useState(defaultQuantity);
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State for Try-On popup
    const [tryOnImage, setTryOnImage] = useState(null); // State for Try-On image

    const handleQuantityChange = (newQuantity) => {
        if (newQuantity >= 1 && newQuantity <= product.quantity) {
            setQuantity(newQuantity);
        }
    };

    const handleAddToCart = () => {
        if (!token) {
            navigate('/login');
        } else {
            const productToAdd = { ...product, quantity }; // Include quantity
            addToCart(productToAdd);
        }
    };

    const handleAddToWishlist = () => {
        if (!token) {
            navigate('/login');
        } else {
            addToWishlist(product);
        }
    };

    const openPopup = () => {
        if (product.try_on_image) {
            setTryOnImage(`${ApiUrl}/uploads/${product.try_on_image}`);
            setIsPopupOpen(true);
        }
    };

    const closePopup = () => setIsPopupOpen(false);

    return (
        <div className="fz-product-details__actions">
            <div className="fz-product-details__quantity cart-product__quantity">
                <button 
                    className="minus-btn cart-product__minus" 
                    onClick={() => handleQuantityChange(quantity - 1)}
                    disabled={quantity <= 1} 
                >
                    <i className="fa-light fa-minus"></i>
                </button>
                <input
                    type="number"
                    name="product-quantity"
                    className="cart-product-quantity-input"
                    value={quantity}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        handleQuantityChange(isNaN(value) ? 1 : value);
                    }} 
                    min="1"
                />
                <button 
                    className="plus-btn cart-product__plus" 
                    onClick={() => handleQuantityChange(quantity + 1)}
                    disabled={quantity >= product.quantity} 
                >
                    <i className="fa-light fa-plus"></i>
                </button>
            </div>
            <button className="fz-product-details__add-to-cart" onClick={handleAddToCart}>
                {t('Add to Cart')}
            </button>
            <button className="fz-product-details__add-to-wishlist" onClick={handleAddToWishlist}>
                <i className="fa-light fa-heart"></i>
            </button>

            {/* Try-On Button */}
            {product.try_on_image && (
    <button className="fz-product-details__try-on" onClick={openPopup}>
        <img src={IconTryOn} alt="Try On Icon" className="try-on-icon" />
        {t('Try On')} 
    </button>
)}
            {/* Try-On Popup */}
            {isPopupOpen && (
                <div className="try-on-popup">
                    <div className="try-on-content">
                        <button className="close-popup" onClick={closePopup}>Close</button>
                        <VirtualTryOn tryOnImage={tryOnImage} /> {/* Render Virtual Try-On */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetailAction;
