import React from 'react'
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection'
import ShippingMethodSection from '../checkout/ShippingMethodSection'
import { useTranslation } from 'react-i18next'

const ShippingMethodtMain = () => {
    const { t } = useTranslation();
  return (
    <>
    <BreadcrumbSection title={t('Shipping method')} current={t('Shipping method')} />
    <ShippingMethodSection/>
    </>
  )
}

export default ShippingMethodtMain