import React, { useState, useEffect } from 'react';
import CommentFormSection from '../forms/CommentFormSection';
import BlogTagFilter from './BlogTagFilter';
import BlogCategoryFilter from './BlogCategoryFilter';
import RecentBlogSection from './RecentBlogSection';
import SearchFilter from './SearchFilter';
import { Link, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const apiUrl = import.meta.env.VITE_API_URL;


const BlogDetailSection = () => {
    const {t, i18n} = useTranslation();
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug_en } = useParams();
    const [categories, setCategories] = useState({}); // new state to store category data
    const [tags, setTags] = useState({}); // new state to store category data
    const [socials, setSocials] = useState([]); // new state to store social data
// SEO Start
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const keywords = {
      en: "sunglasses, eyeglasses, contact lenses, eyewear chains, Cartier eyewear, Mont Blanc glasses, Maybach eyewear, Lotos glasses, Lucas de Stael eyewear, Freddie Wood eyewear, Dita sunglasses, Leisure Society eyewear, IC Berlin glasses",
      ar: "نظارات شمسية, نظارات طبية, عدسات لاصقة, سلاسل النظارات, نظارات كارتييه, نظارات مونت بلانك, نظارات مايباخ, نظارات لوتس, نظارات لوكاس دو ستيل, نظارات فريدي وود, نظارات ديتا, نظارات ليجر سوسايتي, نظارات آي سي برلين"
    };
    const titles = {
      en: "Regalesyes | ",
      ar: "ريجل ايز | "
    };  
  
    const currentKeywords = i18n.language === 'ar' ? keywords.ar : keywords.en;
    const currentTitles = i18n.language === 'ar' ? titles.ar : titles.en;
// SEO End

  
    useEffect(() => {
      fetchBlogDetails(slug_en);
    }, [slug_en]);
   
    const fetchBlogDetails = (slug_en) => {
      fetch(`${apiUrl}/api/blog/${slug_en}`)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch blog details');
          }
        })
        .then(data => {
          // Remove HTML tags from description_en
          const plainTextDescription = data.description_en.replace(/<[^>]+>/g, '');
          data.description_en = plainTextDescription;
          setBlog(data);
          setMetaTitle(i18n.language === 'ar' ? data.meta_title_ar : data.meta_title_en);
          setMetaDescription(i18n.language === 'ar' ? data.meta_description_ar : data.meta_description_en);

          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
  
      // fetch category data
      fetchCategories();
      fetchSocials();
      fetchTags();
    };
  
    const fetchCategories = () => {
      axios.get(`${apiUrl}/api/categoryBlogs`)
        .then(response => {
          const categoriesData = response.data.reduce((acc, category) => {
            acc[category.id] = i18n.language === 'ar' ? category.name_ar : category.name_en;
            return acc;
          }, {});
          setCategories(categoriesData);
        })
        .catch(error => {
          console.error(error);
        });
    };
  
    const fetchSocials = () => {
      axios.get(`${apiUrl}/api/socials`)
        .then(response => {
          setSocials(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    };
  
    const fetchTags = () => {
      axios.get(`${apiUrl}/api/tags`)
        .then(response => {
          const tagsData = response.data.reduce((acc, tag) => {
            acc[tag.id] = i18n.language === 'ar' ? tag.name_ar : tag.name_en;
            return acc;
          }, {});
          setTags(tagsData);
        })
        .catch(error => {
          console.error(error);
        });
    };
    
    return (
      <>
          <Helmet>
  <title>{currentTitles} {metaTitle}</title>
  <meta name="description" content={metaDescription} />
  <meta name="keywords" content={currentKeywords} />
  </Helmet>
    <div className="container">
        <main className="blog-page-content">
            <div className="blogs-container fz-blog-details-container">
                <div className="blogs">
                    <div className="fz-single-blog fz-inner-page-blog fz-blog-details">
                        <div className="fz-single-blog__img fz-blog-details__img">
                            <img src={`${apiUrl}/uploads/${blog.image}`} alt={i18n.language === 'ar' ? blog.title_ar : blog.title_en} />

                            <div className="fz-blog-details__heading">
                                <h3 className="fz-single-blog__title fz-blog-details__title">{i18n.language === 'ar' ? blog.title_ar : blog.title_en}</h3>

                                <div className="fz-single-blog__infos fz-blog-details__infos">
                                    <span className="fz-single-blog__category fz-blog-details__category"><Link to="#">{categories[blog.categoryBlog_id]}</Link></span>
                                    <span className="fz-single-blog__date fz-blog-details__date">{moment(blog.date).format('MM/DD/YYYY')}</span>
                                </div>
                            </div>
                        </div>

                        <div className="fz-single-blog__txt fz-blog-details__txt">
                            <div className="fz-single-blog__desc fz-blog-details__descr">
                            {i18n.language === 'ar' ? blog.description_ar : blog.description_en}
                            </div>
                        </div>
                    </div>

                    <div className="fz-blog-details__tags-n-shares d-flex align-items-center justify-content-between">
                        <div className="fz-blog-details__tags">
                            <span>{t('Tags:')}</span>
                            <Link to="#">{tags[blog.tag_id]}</Link>


                        </div>

                        <div className="fz-blog-details__shares">
                            <span>Share:</span>
                            {socials.map((social) => (
                            <a className="share-option" href={social.link}>
                           <img  src=  {`${apiUrl}/uploads/${social.icon}`}
                      />
                            </a>
                        ))}
                        </div>
                    </div>
                </div>
            </div>

            <aside className="fz-sidebar blog-page-sidebar">
                <SearchFilter/>

                <RecentBlogSection/>

                <BlogCategoryFilter/>

                <BlogTagFilter/>
            </aside>
        </main>
    </div>
    </>
  )
}

export default BlogDetailSection