import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = import.meta.env.VITE_API_URL;
const TrackOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [orderNo, setOrderNo] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);
  const [error, setError] = useState(null);

  const handleOrderNoChange = (e) => {
    setOrderNo(e.target.value);
  };

  const handleTrackOrder = (e) => {
    e.preventDefault(); // Prevents default form submission behavior
    axios.get(`${apiUrl}/api/track-order?order_no=${orderNo}`)
      .then(response => {
        const status = response.data.status;
        console.log('Received status:', status);
        switch (status) {
          case "0":
            setOrderStatus(t('Pending'));
            break;
          case "1":
            setOrderStatus(t('Confirmed'));
            break;
          case "2":
            setOrderStatus(t('Rejected'));
            break;
          case "3":
            setOrderStatus(t('Pickup'));
            break;
          case "4":
            setOrderStatus(t('On the way'));
            break;
          case "5":
            setOrderStatus(t('Delivered'));
            break;
          default:
            setOrderStatus(t('Not found'));
        }
        navigate(`/track-order?order_no=${orderNo}`);
      })
      .catch(error => {
        setError(t('Error tracking order'));
      });
  };

  return (
    <Layout>
      <div className="container p-2">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title text-center">{t('Track Order')}</h2>
                <form onSubmit={handleTrackOrder}> {/* Use onSubmit on form */}
                  <div className="form-group">
                    <label htmlFor="orderNo">{t('Order Number')}:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="orderNo"
                      name='order_no'
                      value={orderNo}
                      onChange={handleOrderNoChange}
                      placeholder={t('Enter your order number')}
                    />
                  </div>
                  <br />
                  <button type="submit" className="btn btn-dark p-2 btn-center">{t('Track Order')}</button>
                </form>
                {orderStatus && <p className="text-success">{t('Order Status')}: {orderStatus}</p>}
                {error && <p className="text-danger">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TrackOrder;
