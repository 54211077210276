import React from 'react'
import Layout from '../components/layout/Layout'
import SignUpMain from '../components/main/SignUpMain'

const SignUp = () => {
  return (
    <Layout>
        <SignUpMain/>
    </Layout>
  )
}

export default SignUp