import React from 'react'
import Layout from '../components/layout/Layout'
import BooktMain from '../components/main/BooktMain'


const BookAppointment = () => {
  return (
    <Layout>
        <BooktMain />
    </Layout>
  )
}

export default BookAppointment