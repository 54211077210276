import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactFormSection from '../forms/ContactFormSection';
import { useTranslation } from 'react-i18next';
const apiUrl = import.meta.env.VITE_API_URL;

const ContactSection = () => {
    const [t, i18n] = useTranslation(); 
    const [footers, setFooters] = useState([]);
    const [socials, setSocials] = useState([]);

    useEffect(() => {
        axios.get(`${apiUrl}/api/footers`)
         .then(response => {
            setFooters(response.data[0]);
          })
         .catch(error => {
            console.error(error);
          });
      
        axios.get(`${apiUrl}/api/socials`)
         .then(response => {
            setSocials(response.data);
          })
         .catch(error => {
            console.error(error);
          });
      }, []);


  return (
    <div className="container">
        <div className="fz-inner-contact-details">
            <div className="fz-inner-contact-details__left">
                <div className="fz-blog-details__comment-form">
                    <h4 className="fz-comment-form__title fz-inner-contact-details__title">{t('Leave a Message')}</h4>
                    <ContactFormSection/>
                </div>
            </div>

            <div className="fz-inner-contact-details__info-card">
                <h3 className="fz-inner-contact-details__title">{t('Get In Touch')}</h3>
                <ul>
                    <li className="fz-single-contact-info">
                        <i className="fa-light fa-location-dot"></i>
                        {/* <span className="contact-address">{footers.address_en}</span> */}

                        <span className="contact-address">{i18n.language === 'ar' ? footers.address_ar : footers.address_en}</span>
                    </li>

                    <li className="fz-single-contact-info">
                        <i className="fa-light fa-phone"></i>
                        <div className="contact-numbers">
                            <span><a href={`tel:${footers.phone}`}>{footers.phone}</a></span>
                        </div>
                    </li>

                    <li className="fz-single-contact-info">
                        <i className="fa-light fa-clock"></i>
                        <div className="contact-numbers">
                            <span className="store-time__title">{t('Store Hours:')}</span>
                            <span className="store-time__hours">{t('Sat-Thu 10AM - 12AM')}</span>
                            <span className="store-time__hours">{t('Fri 4PM - 12AM')}</span>

                        </div>
                    </li>

                    <li className="contact-socials">
                        <h6 className="contact-socials__title">{t('Follow Us:')}</h6>
                        <ul>
                        {socials.map((social) => (
                            <li><a href={social.link}>
                                 <img  src=  {`${apiUrl}/uploads/${social.icon}`} />
                                </a></li>
                        ))}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default ContactSection