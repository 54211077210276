import ReactGA from "react-ga4";

const initializeAnalytics = (measurementId) => {
  ReactGA.initialize(measurementId);
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category, action, label, value) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

export default initializeAnalytics;
