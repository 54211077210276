import React from "react";
import Layout from "../components/layout/Layout";
import ShippingAddressMain from "../components/main/ShippingAddressMain";



const ShippingAddress = () => {
  return (
    <Layout>
      <ShippingAddressMain />
    </Layout>
  );
};

export default ShippingAddress;
