import React, { useState, useEffect } from "react";
import { blogList } from '../../data/Data';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';



const RecentBlogSection = () => {
    const [t, i18n] = useTranslation();

    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState({}); // new state to store category data


    useEffect(() => {
        axios.get("http://localhost:8000/api/blogs")
         .then(response => {
            const blogsData = response.data.map(blog => ({
              id: blog.id,
              slug_en: blog.slug_en,
              slug_ar: blog.slug_ar,
              title_en: blog.title_en,
              title_ar: blog.title_ar,
              imgSrc: blog.image,
              desc_en: blog.description_en,
              desc_ar: blog.description_ar,
              categoryBlog_id: blog.categoryBlog_id,
              date: blog.created_at 
            }));
            setBlogs(blogsData);
          })
         .catch(error => {
            console.error(error);
          });
    
        // fetch category data
        axios.get("http://localhost:8000/api/categoryBlogs")
         .then(response => {
            const categoriesData = response.data.reduce((acc, category) => {
                acc[category.id] = i18n.language === 'en' ? category.name_en : category.name_ar;
              return acc;
            }, {});
            setCategories(categoriesData);
          })
         .catch(error => {
            console.error(error);
          });
      }, []);

  return (
    <section className="sidebar-single-area blog-area">
        <h3 className="sidebar-single-area__title">{t('Recent post')}</h3>
        {blogs.slice(0,3).map((blog) => (
            <div className="fz-sidebar-single-blog" key={blog.id}>
                <div className="fz-sidebar-single-blog__img">
                    <img src={`http://127.0.0.1:8000/uploads/${blog.imgSrc}`} alt="blog image"/>
                </div>

                <div className="fz-sidebar-single-blog__txt fz-single-blog fz-inner-page-blog">
                    <div className="fz-sidebar-single-blog__infos fz-single-blog__infos">
                        <span className="fz-single-blog__category"><Link to="#">{categories[blog.categoryBlog_id]}</Link></span>
                        <span className="fz-single-blog__date">{moment(blog.date).format('MM/DD/YYYY')}</span>
                    </div>

                    <h5 className="fz-sidebar-single-blog__title"><Link to={`/blogDetails/${blog.slug_en}`}>{i18n.language === 'en' ? blog.title_en : blog.title_ar}</Link></h5>
                </div>
            </div> 
        ))}
        
    </section>
  )
}

export default RecentBlogSection