import React, { useContext, useState, useEffect } from 'react';
import CartItemTable from './CartItemTable';
import { FarzaaContext } from '../../context/FarzaaContext';
import { StoreContext } from '../../context/StoreContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
 
const CartSection = () => {  
    const {t} = useTranslation();
    const {
        cartItems,
        handleQuantityChange,
    } = useContext(FarzaaContext)
    const { cart,removeFromCart } = useContext(StoreContext);
  

  return (
    <div className="container">
        <div className="cart-section">
            <div className="cart-center inner-cart">
                <div className="cart-area">
                    <div className="cart__body">
                        <div className="table-responsive">
                            <CartItemTable cartArray={cart} remove={removeFromCart} quantity={handleQuantityChange}/>
                        </div>

                        <div className="cart-left-actions d-flex justify-content-end">
                            {cart.length === 0? (
                              <Link className='fz-1-banner-btn update-cart-btn' to='/shop'>t{('Go to Shop')}</Link>  
                            ):(

                                <Link to="/shipping-method" className="fz-1-banner-btn cart-checkout-btn">{t('Proceed to checkout')}</Link>
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CartSection