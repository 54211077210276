import React from 'react';
import BreadcrumbSection from '../breadcrumb/BreadcrumbSection';
import BlogDetailSection from '../blog/BlogDetailSection';
import { useTranslation } from 'react-i18next';


const BlogDetailsMain = () => {
  const { t } = useTranslation();

  return (
    <>
        <BreadcrumbSection title={t('Blog Details')} current= {`${t('Blog Details')} | ${t('blogs')}`} />
        <BlogDetailSection/>
    </>
  )
}

export default BlogDetailsMain