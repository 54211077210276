import React, { useState } from 'react';
import SignInFormSection from '../forms/SignInFormSection';
import SignInFormSectionPhone from '../forms/SignInFormSectionPhone';
import { useTranslation } from 'react-i18next';

const AuthenticationLoginSection = () => {
  const { t } = useTranslation();
  const [loginMethod, setLoginMethod] = useState('email'); // Default to email

  const handleLoginMethodChange = (event) => {
    setLoginMethod(event.target.value);
  };

  return (
    <section className="fz-account-form-section">
      <div className="container">
        <div className="row g-4 justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-9 col-10 col-xxs-12">
            <h3 className="single-form-title">{t('Login')}</h3>

            {/* Selection for login method */}
            <div className="mb-3 d-flex">
              <label className="d-flex align-items-center p-2">
                <input
                  type="radio"
                  value="email"
                  checked={loginMethod === 'email'}
                  onChange={handleLoginMethodChange}
                />
                <div className="ms-4 text-justify text-sbig text-nowrap">
                <span className="rounded bg-secondary p-1 me-2">

                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" className="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                  </svg>
                  </span>

                  {t('Login with Email')}
                </div>
              </label>
              {/* <label className="d-flex align-items-center p-3">
                <input
                  type="radio"
                  value="phone"
                  checked={loginMethod === 'phone'}
                  onChange={handleLoginMethodChange}
                />
<div className="ms-4 text-justify text-sbig text-nowrap">
  <span className="rounded bg-secondary p-1 me-2"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" className="bi bi-phone" viewBox="0 0 16 16">
      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
    </svg>
  </span>
  {t('Login with Phone')}
</div> 
              </label> */}
            </div>

            {/* Render the appropriate form based on selection */}
            {loginMethod === 'email' ? <SignInFormSection /> : null} 
            {/* {loginMethod === 'email' ? <SignInFormSection /> : <SignInFormSectionPhone />} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthenticationLoginSection;