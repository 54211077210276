import React, { useState, useEffect } from "react";
import axios from "axios";
import BookingFormSection from '../forms/BookingFormSection';
import { useTranslation } from 'react-i18next';


const BookingSection = () => {
    const {t} = useTranslation(); 




  return (
    <div className="container">
        <div className="fz-inner-contact-details">
            <div className="fz-inner-contact-details__left">
                <div className="fz-blog-details__comment-form">
                    <h4 className="fz-comment-form__title fz-inner-contact-details__title">{t('Book Appointment')}</h4>
                    <BookingFormSection/>
                </div>
            </div>

        </div>
    </div>
  )
}

export default BookingSection