import React, { useContext } from "react";
import { CurrencyContext } from '../../context/CurrencyContext'; // Adjust the path as necessary
import { useTranslation } from "react-i18next";

const CustomCurrency = () => {
  const { selectedCurrency, changeCurrency } = useContext(CurrencyContext);
  const { t } = useTranslation(); 


  const handleCurrencyChange = (event) => {
    changeCurrency(event.target.value);
  };

  return (
    <>
      <select name="currency" id="top-header-currency-dropdown" className="form-select-sm" onChange={handleCurrencyChange} value={selectedCurrency}>
        <option value="SAR">{t('SAR')}</option>
        <option value="USD">{t('USD')}</option>
        <option value="Euro">{t('Euro')}</option>
        <option value="Pound">{t('Pound')}</option>
      </select>
    </>
  );
};

export default CustomCurrency;